@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans", sans-serif;
}

@layer components {
  .nav-link {
    @apply text-base font-semibold leading-6 text-white hover:text-indigo-400;
  }

  .nav-link.active {
    @apply text-indigo-400;
  }
}

.logo-text {
  color: #5e17eb;
  text-shadow: 0.0625rem 0.0625rem 0 #fff;
}

.magic-button {
  --border-bottom-width: 1px;
  --border-color: #5e17eb; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    #5e17eb,
    #4d15c4 78.9465%
  ); /* Updated gradient */
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -6px #5e17eb,
    /* Updated shadow */ inset 0 5px 10px -8px #9e5def,
    /* Updated shadow */ inset 0 -5px 10px -8px #9e5def,
    /* Updated shadow */ inset -5px 0 10px -8px #9e5def,
    /* Updated shadow */ inset 5px 0 10px -8px #9e5def; /* Updated shadow */
}

.magic-button:hover {
  --border-bottom-width: 1px;
  --border-color: #4d15c4; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    #5e17eb,
    #874cd6 78.9465%
  ); /* Updated gradient */
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -3px #5e17eb,
    /* Updated shadow */ inset 0 5px 20px -7px #9e5def,
    /* Updated shadow */ inset 0 -5px 20px -7px #9e5def,
    /* Updated shadow */ inset -5px 0 20px -7px #9e5def,
    /* Updated shadow */ inset 5px 0 20px -7px #9e5def; /* Updated shadow */
}

.bg-gradient-1 {
  background: linear-gradient(
    180deg,
    #5e17eb 0%,
    #2a2d34 15.47%,
    #202227 33.49%,
    #202227 63.16%,
    #2a2d34 85.69%,
    #5e17eb 100%
  );
}
